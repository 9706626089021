import { InfoCard, StatusTagV2 } from 'component-library'
import LinkComponent from '../../LinkComponent/LinkComponent'
import { ContentType } from '../../../context/GenericSearchContext/GenericSearchContext.constants'
import './SearchResultTile.scss'

interface ISearchResultTileProps {
  title: string
  link: string
  thumbnail: string
  tag: ContentType
  challengeDifficulty?: 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED' | null
  userHandle?: string
}

const SearchResultTile = ({
  title,
  link,
  thumbnail,
  tag,
  challengeDifficulty,
  userHandle,
}: ISearchResultTileProps) => {
  const contentTag = useContentTag(tag)
  const isProfile = tag === ContentType.PROFILE

  return (
    <LinkComponent
      href={link}
      addClass="SearchResultTile"
      testId="search-result-tile"
      openInCurrentTab
    >
      <InfoCard
        tagsTopRight={
          ContentType.CHALLENGE && challengeDifficulty ? (
            <StatusTagV2 status={challengeDifficulty} />
          ) : null
        }
        label={title}
        description={userHandle ? `@${userHandle}` : undefined}
        image={thumbnail}
        tag={contentTag}
        className={`SearchResultTile__InfoCard ${
          isProfile ? 'SearchResultTile__InfoCard--profile' : ''
        }`}
      />
    </LinkComponent>
  )
}

const useContentTag = (tag: ContentType | string) => {
  let contentTag: JSX.Element | null = null

  switch (tag) {
    case ContentType.DEMO:
      contentTag = (
        <span className="SearchResultTile__tag">
          <i className="bx bxs-flask"></i> <span>Demo</span>
        </span>
      )
      break
    case ContentType.BLOG:
      contentTag = (
        <span className="SearchResultTile__tag">
          <i className="bx bxs-quote-alt-left"></i> <span>Blog</span>
        </span>
      )
      break
    case ContentType.CHALLENGE:
      contentTag = (
        <span className="SearchResultTile__tag">
          <i className="bx bxs-trophy"></i> <span>Coding Challenge</span>
        </span>
      )
      break
    case ContentType.PROFILE:
      contentTag = (
        <span className="SearchResultTile__tag">
          <i className="bx bxs-user-circle"></i> <span>Profile</span>
        </span>
      )
      break
    default:
      null
  }

  return contentTag
}

export default SearchResultTile

import { useState, useCallback, useEffect } from 'react'

type UsePaginationProps = {
  totalItems: number
  limit: number
  initialPage?: number
  onPageChange?: () => void
}

export type UsePaginationReturn = {
  offset: number
  currentPage: number
  totalPages: number
  hasNext: boolean
  hasPrevious: boolean
  handleNextPage: () => void
  handlePreviousPage: () => void
  handlePageInput: (value: number) => void
}

const usePagination = ({
  totalItems,
  limit,
  initialPage = 1,
  onPageChange,
}: UsePaginationProps): UsePaginationReturn => {
  const [offset, setOffset] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(initialPage)
  const totalPages: number = Math.ceil(totalItems / limit)

  const hasNext: boolean = offset + limit < totalItems
  const hasPrevious: boolean = offset > 0

  const handleNextPage = useCallback(() => {
    if (hasNext) {
      setOffset(offset + limit)
      setCurrentPage(currentPage + 1)
      onPageChange?.()
    }
  }, [hasNext, offset, currentPage, limit])

  const handlePreviousPage = useCallback(() => {
    if (hasPrevious) {
      setOffset(offset - limit)
      setCurrentPage(currentPage - 1)
      onPageChange?.()
    }
  }, [hasPrevious, offset, currentPage, limit])

  const handlePageInput = useCallback(
    (value: number) => {
      if (value === currentPage) return

      const newOffset =
        value > 0 && value <= totalPages
          ? (value - 1) * limit
          : value > totalPages
          ? (totalPages - 1) * limit
          : 0
      setOffset(newOffset)
      setCurrentPage(value > totalPages ? totalPages : value < 1 ? 1 : value)
      onPageChange?.()
    },
    [currentPage, totalPages, limit]
  )

  useEffect(() => {
    setCurrentPage(Math.ceil(offset / limit) + 1)
  }, [offset, limit])

  /* We want to reset current page if total changes */
  useEffect(() => {
    setOffset(0)
    setCurrentPage(1)
  }, [totalItems])

  /* If user passes a initial page we need to make sure it happens  */
  useEffect(() => {
    if (initialPage > 0 && initialPage <= totalPages) {
      setOffset((initialPage - 1) * limit)
      setCurrentPage(initialPage)
    }
  }, [initialPage, totalPages, limit])

  return {
    offset: Math.max(0, offset),
    currentPage,
    totalPages,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    handlePageInput,
  }
}

export default usePagination

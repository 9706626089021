export default {
  /* SEO */
  meta_title: 'PennyLane — Results',
  meta_description:
    'A Python library for quantum machine learning, automatic differentiation, and optimization of hybrid quantum-classical computations. Use multiple hardware devices, alongside TensorFlow or PyTorch, in a single computation.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/generic_og.png',

  /* Page Title (Required for SEO) */
  title: 'Results', // Defaults to 'Search'

  /* No results msg */
  no_results: 'No results found at this time. Try again!',
}

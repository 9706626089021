import content from '../../content/search'

const NoSearchPageResults = () => {
  return (
    <div data-testid="no-search-page-results" className="w-full">
      <p className="text-center font-quicksand font-semibold text-2xl py-10 md:py-[124px]">
        {content.no_results}
      </p>
    </div>
  )
}

export default NoSearchPageResults

import Seo from '../components/seo'
import content from '../content/search'
import SearchLayout from '../components/SearchPage/SearchLayout/SearchLayout'
import '../scss/pages/search.scss'
import { GenericSearchContextProvider } from '../context/GenericSearchContext/GenericSearchContext'

const SearchPage = () => {
  return (
    <GenericSearchContextProvider>
      <SearchLayout />
    </GenericSearchContextProvider>
  )
}

export default SearchPage

const { meta_title, meta_description, meta_image } = content
export const Head = () => (
  <Seo title={meta_title} description={meta_description} image={meta_image} />
)

import NoSearchPageResults from '../NoSearchPageResults'
import FilterBar from '../SearchFilters/FilterBar/FilterBar'
import FilterModal from '../SearchFilters/FilterModal/FilterModal'
import SearchHeader from './SearchHeader'
import SearchResultTile from '../SearchResultTile/SearchResultTile'
import PaginationInput from '../../PaginationInput/PaginationInput'
import LoaderSpinner from '../../LoaderSpinner/LoaderSpinner'
import { useGenericSearchContext } from '../../../context/GenericSearchContext/GenericSearchContext'
import { ContentType } from '../../../context/GenericSearchContext/GenericSearchContext.constants'
import './SearchLayout.scss'

const SearchLayout = () => {
  /* STATE */
  const { pagination, searchResults } = useGenericSearchContext()
  const profileImageFallback =
    'https://assets.cloud.pennylane.ai/profiles/fallback-avatar.png'
  const hasResults = searchResults?.data?.search?.items?.length || 0 > 0

  return (
    <div className="SearchLayout" data-testid="search-layout">
      <SearchHeader />
      <div className="SearchLayout__container">
        <FilterBar />
        <div className="SearchLayout__tiles" data-testid="search-layout-tiles">
          <FilterModal />
          {searchResults?.loading && (
            <div
              data-testid="loading-search-results"
              className="SearchLayout__alt-content"
            >
              <LoaderSpinner />
            </div>
          )}
          {searchResults?.error && (
            <div
              data-testid="search-error"
              className="SearchLayout__alt-content"
            >
              <p>Unable to load results, please try again later.</p>
            </div>
          )}
          {hasResults &&
            searchResults?.data?.search?.items?.map((searchItem) => {
              const isProfile = searchItem.type === ContentType.PROFILE
              const { firstName, lastName } = searchItem.profile || {}

              const fullName =
                firstName || lastName
                  ? `${firstName || ''} ${lastName || ''}`.trim()
                  : ''

              const thumbnail =
                isProfile && !searchItem.thumbnail
                  ? profileImageFallback
                  : searchItem.thumbnail

              return (
                <SearchResultTile
                  key={searchItem.id}
                  title={isProfile ? fullName : searchItem.title}
                  link={searchItem.link}
                  thumbnail={thumbnail}
                  tag={searchItem.type}
                  userHandle={searchItem.profile?.handle}
                  challengeDifficulty={
                    searchItem.type === ContentType.CHALLENGE
                      ? searchItem.standaloneChallenge?.difficulty
                      : null
                  }
                />
              )
            })}
          {!hasResults && !searchResults?.loading && !searchResults?.error && (
            <NoSearchPageResults />
          )}
        </div>
      </div>
      {hasResults && (
        <div className="py-10">
          <PaginationInput
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            hasNext={pagination.hasNext}
            hasPrevious={pagination.hasPrevious}
            handleNextPage={pagination.handleNextPage}
            handlePreviousPage={pagination.handlePreviousPage}
            handlePageInput={pagination.handlePageInput}
          />
        </div>
      )}
    </div>
  )
}

export default SearchLayout

import Select from 'react-select'
import { useGenericSearchContext } from '../../../../context/GenericSearchContext/GenericSearchContext'
import {
  SortOption,
  sortFilterOptions,
} from '../../../../context/GenericSearchContext/GenericSearchContext.constants'
import './FilterBar.scss'

const FilterBar = () => {
  const {
    isFilterModalOpen,
    setIsFilterModalOpen,
    totalFiltersCount,
    sort,
    setSort,
    pagination,
    searchResults,
  } = useGenericSearchContext()

  return (
    <div className="FilterBar" data-testid="filter-bar">
      <div className="FilterBar__container">
        <div className="FilterBar__left">
          <label hidden htmlFor="relevance-sort">
            Relevance
          </label>
          <Select
            id="relevance-sort"
            aria-labelledby="relevance-sort"
            name="relevance-sort"
            isSearchable={false}
            value={sort}
            className="FilterBar__sort-select"
            classNamePrefix="FilterBar__sort-select"
            placeholder={<span className="text-grey-2">Relevance</span>}
            options={sortFilterOptions}
            formatOptionLabel={(option, { context }) => {
              const isMenu = context === 'menu'
              const isSelected = option === sort
              return (
                <div className="flex gap-2 justify-between items-center text-sm text-grey-2">
                  <span className="FilterBar__sort-option__label">
                    {option.label}
                  </span>
                  {isMenu && (
                    <span className="FilterBar__sort-option__icon">
                      {isSelected && (
                        <i className="bx bx-check text-grey-2 text-xl"></i>
                      )}
                    </span>
                  )}
                </div>
              )
            }}
            onChange={(newValue) => {
              setSort(newValue as SortOption)
              pagination.handlePageInput(1)
            }}
          />
        </div>
        <div className="FilterBar__right">
          {!searchResults?.loading && (
            <p className="FilterBar__right__count text-2xl">
              {searchResults?.data?.search?.totalItems || 0}{' '}
              {searchResults?.data?.search?.totalItems === 1
                ? 'result'
                : 'results'}
            </p>
          )}
          <button
            onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
            className="FilterBar__right__filter-button"
            aria-expanded={isFilterModalOpen}
          >
            <span className="text-grey-2">Filters</span>{' '}
            <span className="Filter__count text-sm">{totalFiltersCount}</span>{' '}
            <i className="bx bx-filter text-grey-2"></i>
          </button>
          {isFilterModalOpen && <div className="FilterBar__right__cover" />}
        </div>
      </div>
    </div>
  )
}

export default FilterBar

import { useEffect, useState } from 'react'
import './PaginationInput.scss'

type PaginationInputProps = {
  currentPage: number
  totalPages: number
  hasNext: boolean
  hasPrevious: boolean
  handleNextPage: () => void
  handlePreviousPage: () => void
  handlePageInput: (value: number) => void
}

/**
 * For best results use this component with usePagination hook
 */
const PaginationInput = ({
  currentPage,
  totalPages,
  hasNext,
  hasPrevious,
  handleNextPage,
  handlePreviousPage,
  handlePageInput,
}: PaginationInputProps) => {
  const [inputValue, setInputValue] = useState(currentPage.toString())
  const [inputWidth, setInputWidth] = useState('24px')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    setInputValue(currentPage.toString())
  }, [currentPage])

  useEffect(() => {
    const chars = totalPages.toString().length
    setInputWidth(`${chars + 2}ch`)
  }, [totalPages])

  return (
    <div className="PaginationInput" data-testid="pagination-input">
      <button
        className="PaginationInput__button"
        onClick={handlePreviousPage}
        disabled={!hasPrevious}
        aria-label="previous page"
        data-testid="pagination-previous-page-button"
      >
        <i className="bx bxs-left-arrow"></i>
      </button>
      <div className="PaginationInput__counts">
        <form
          style={{ width: inputWidth }}
          className="PaginationInput__form"
          onSubmit={(e) => {
            e.preventDefault()
            const newPage = parseInt(inputValue)
            if (!isNaN(newPage)) {
              handlePageInput(parseInt(inputValue))
            } else {
              setInputValue(currentPage.toString())
            }
          }}
          data-testid="pagination-input-form"
        >
          <label
            id="pagination-input-label"
            className="sr-only"
            htmlFor="pagination-input"
          >
            Current page
          </label>
          <input
            id="pagination-input"
            aria-labelledby="pagination-input-label"
            onBlur={() => setInputValue(currentPage.toString())}
            className={`PaginationInput__input w-full`}
            value={inputValue}
            onChange={(e) => {
              handleInputChange(e)
            }}
            disabled={!hasPrevious && !hasNext}
            data-testid="pagination-input-input"
          />
        </form>{' '}
        <span className="PaginationInput__count PaginationInput__count-separator">
          of
        </span>
        <span className="PaginationInput__count">{totalPages}</span>
      </div>
      <button
        className="PaginationInput__button"
        onClick={handleNextPage}
        disabled={!hasNext}
        aria-label="next page"
        data-testid="pagination-next-page-button"
      >
        <i className="bx bxs-right-arrow"></i>
      </button>
    </div>
  )
}

export default PaginationInput

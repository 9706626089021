import { useEffect, useState } from 'react'
import SearchInput from '../../SearchInput/SearchInput'
import content from '../../../content/search'
import { useGenericSearchContext } from '../../../context/GenericSearchContext/GenericSearchContext'
import { contentTypes } from '../../../context/GenericSearchContext/GenericSearchContext.constants'
import './SearchLayout.scss'

const SearchHeader = () => {
  const {
    query,
    handleQuerySubmit,
    clearQuery,
    handleContentTypeChange,
    contentType,
  } = useGenericSearchContext()
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setSearchValue(query)
  }, [query])

  return (
    <div className="SearchHeader" data-testid="search-header">
      <div className="SearchHeader__container">
        <h1 className="SearchHeader__title" data-testid="search-header-title">
          {content?.title || 'Search'}
        </h1>
        <form
          className="SearchHeader__form"
          onSubmit={(e) => {
            e.preventDefault()
            handleQuerySubmit(searchValue)
          }}
          data-testid="search-header-form"
        >
          <SearchInput
            value={searchValue}
            onInputChange={setSearchValue}
            onClear={() => {
              setSearchValue('')
              clearQuery()
            }}
          />
        </form>
        <div
          role="radiogroup"
          aria-label="Content Type Filter"
          className="flex justify-center flex-wrap px-[18px] md:px-0 
            md:gap-x-6 md:pt-10 gap-x-4 gap-y-2 py-6 md:py-0"
        >
          {contentTypes.map((type) => {
            const active = contentType === type.value
            return (
              <button
                role="radio"
                key={type.label}
                onClick={() => handleContentTypeChange(type.value)}
                aria-label={`Show: ${type.label}`}
                aria-checked={active}
                className={`SearchHeader__content-toggle ${
                  active ? 'SearchHeader__content-toggle--active' : ''
                }`}
              >
                {type.label}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SearchHeader

import { useEffect, useRef } from 'react'
import { useCloseOnEscape } from 'shared-utilities'
import { useScrollLock, useMediaQuery, useOnClickOutside } from 'usehooks-ts'
import FocusTrap from 'react-focus-lock'
import LoaderSpinner from '../../../LoaderSpinner/LoaderSpinner'
import { useGenericSearchContext } from '../../../../context/GenericSearchContext/GenericSearchContext'
import {
  ContentType,
  resolveSocialMediaLabel,
} from '../../../../context/GenericSearchContext/GenericSearchContext.constants'
import './FilterModal.scss'

const FilterModal = () => {
  const {
    contentType,
    isFilterModalOpen,
    setIsFilterModalOpen,
    categories,
    years,
    basedOnPapers,
    toggleCategory,
    categoryCount,
    toggleYear,
    yearCount,
    toggleBasedOnPapers,
    papersCount,
    clearCategories,
    clearYears,
    clearBasedOnPapers,
    clearAllFilters,
    isLoadingFilterData,
    filterDataErrorMessage,
    totalFiltersCount,
    categoryFilters,
    yearFilters,
    basedOnPapersAvailable,
    difficultyFilters,
    difficulties,
    difficultyCount,
    selectDifficulties,
    clearDifficulties,
    socialMedia,
    socialMediaFilters,
    toggleSocialMedia,
    socialMediaCount,
    clearSocialMedia,
  } = useGenericSearchContext()

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsFilterModalOpen(false))
  const isMobile = useMediaQuery('(max-width: 767px)')
  useCloseOnEscape(isFilterModalOpen, () => setIsFilterModalOpen(false))
  const { lock, unlock, isLocked } = useScrollLock({
    autoLock: false,
  })

  useEffect(() => {
    if (isMobile && isFilterModalOpen) {
      lock()
    }
    if (!isFilterModalOpen || (!isMobile && isLocked)) {
      unlock()
    }
    return () => unlock()
  }, [isFilterModalOpen, isMobile])

  // we need to determine whether or not there are filters based on content type and the categories
  const noBlogFilters =
    contentType === ContentType.BLOG &&
    categoryFilters.length === 0 &&
    yearFilters.length === 0

  const noDemoFilters =
    contentType === ContentType.DEMO &&
    categoryFilters.length === 0 &&
    yearFilters.length === 0 &&
    !basedOnPapersAvailable

  const noChallengeFilters =
    contentType === ContentType.CHALLENGE &&
    categoryFilters.length === 0 &&
    difficultyFilters.length === 0 &&
    yearFilters.length === 0

  const noAllFilters =
    !contentType &&
    categoryFilters.length === 0 &&
    yearFilters.length === 0 &&
    !basedOnPapersAvailable

  return isFilterModalOpen ? (
    <div
      data-testid="filter-modal"
      id="filter-modal"
      className="FilterModal"
      ref={ref}
    >
      <FocusTrap
        className="FilterModal__container"
        disabled={!isFilterModalOpen}
      >
        <div className="FilterModal__content">
          {isLoadingFilterData && (
            <div
              className="FilterModal__alt-content"
              data-testid="loading-filters"
            >
              <LoaderSpinner />
            </div>
          )}
          {!!filterDataErrorMessage && (
            <div
              className="FilterModal__alt-content"
              data-testid="filter-error"
            >
              <p>There was an issue loading the filters</p>
            </div>
          )}
          {!isLoadingFilterData &&
            (noAllFilters ||
              noBlogFilters ||
              noChallengeFilters ||
              (noDemoFilters && !filterDataErrorMessage)) && (
              <div
                className="FilterModal__alt-content"
                data-testid="no-filters-available"
              >
                <p>There are no filters available.</p>
              </div>
            )}
          {!isLoadingFilterData && !filterDataErrorMessage && (
            <>
              <div className="FilterModal__header">
                <button
                  disabled={totalFiltersCount === 0}
                  className="FilterModal__clear"
                  onClick={clearAllFilters}
                  data-testid="clear-all-filters"
                >
                  Clear all filters{' '}
                  <span className="Filter__count">{totalFiltersCount}</span>
                </button>
              </div>
              {categoryFilters.length > 0 && (
                <FilterToggleList
                  label="Categories"
                  clearFunc={clearCategories}
                  activeToggleCount={categoryCount}
                  testId="filter-toggle-list-categories"
                >
                  {categoryFilters.map((category) => {
                    const value = category.toLocaleLowerCase()
                    const label = category

                    return (
                      <FilterToggle
                        key={category}
                        label={label}
                        isActive={categories.includes(value)}
                        onClick={() => toggleCategory(value)}
                      />
                    )
                  })}
                </FilterToggleList>
              )}
              {contentType === ContentType.PROFILE && (
                <FilterToggleList
                  label="Social Media"
                  clearFunc={clearSocialMedia}
                  activeToggleCount={socialMediaCount}
                  testId="filter-toggle-list-social-media"
                >
                  {socialMediaFilters.map((socialMediaFilter) => {
                    return (
                      <FilterToggle
                        key={`social-media-${socialMediaFilter}`}
                        label={resolveSocialMediaLabel(socialMediaFilter)}
                        isActive={socialMedia.includes(socialMediaFilter)}
                        onClick={() => toggleSocialMedia(socialMediaFilter)}
                      />
                    )
                  })}
                </FilterToggleList>
              )}
              {contentType === ContentType.CHALLENGE &&
                difficultyFilters.length > 0 && (
                  <FilterToggleList
                    label="Difficulties"
                    clearFunc={clearDifficulties}
                    activeToggleCount={difficultyCount}
                    testId="filter-toggle-list-difficulties"
                  >
                    {difficultyFilters.map((difficulty) => (
                      <FilterToggle
                        isDifficultyFilter
                        key={difficulty}
                        label={difficulty
                          .toLowerCase()
                          .replace(/\b\w/g, (s) => s.toUpperCase())}
                        isActive={difficulties.includes(difficulty)}
                        onClick={() => selectDifficulties(difficulty)}
                      />
                    ))}
                  </FilterToggleList>
                )}
              {yearFilters.length > 0 && (
                <FilterToggleList
                  label="Year"
                  clearFunc={clearYears}
                  activeToggleCount={yearCount}
                  testId="filter-toggle-list-years"
                >
                  {yearFilters.map((year) => (
                    <FilterToggle
                      key={year}
                      label={year.toString()}
                      isActive={years.includes(year)}
                      onClick={() => toggleYear(year)}
                    />
                  ))}
                </FilterToggleList>
              )}
              {basedOnPapersAvailable && (
                <FilterToggleList
                  label="Based on papers"
                  clearFunc={clearBasedOnPapers}
                  activeToggleCount={papersCount}
                  testId="filter-only-papers"
                >
                  <FilterToggle
                    label="Demos based on papers"
                    isActive={basedOnPapers}
                    onClick={toggleBasedOnPapers}
                  />
                </FilterToggleList>
              )}
            </>
          )}
        </div>

        <div className="FilterModal__footer">
          <button
            className="FilterModal__button"
            onClick={() => setIsFilterModalOpen(false)}
          >
            Close
          </button>
        </div>
      </FocusTrap>
    </div>
  ) : null
}

interface IFilterToggleProps {
  label: string
  isActive: boolean
  isDifficultyFilter?: boolean
  onClick: () => void
}

const FilterToggle = ({
  label,
  isActive,
  isDifficultyFilter = false,
  onClick,
}: IFilterToggleProps) => (
  <button
    role="toggle"
    data-testid="filter-toggle"
    aria-pressed={isActive}
    className={`FilterModal__toggle ${
      isActive
        ? isDifficultyFilter
          ? `FilterModal__toggle--active-${label}`
          : 'FilterModal__toggle--active'
        : ''
    }`}
    onClick={onClick}
  >
    {isDifficultyFilter && <i className="bx bxs-bolt-circle text-base mr-2" />}
    <span>{label}</span>
  </button>
)

interface IFilterToggleListProps {
  label: string
  children: React.ReactNode
  clearFunc: () => void
  activeToggleCount: number
  testId: string
}

const FilterToggleList = ({
  label,
  children,
  clearFunc,
  activeToggleCount,
  testId,
}: IFilterToggleListProps) => (
  <div className="FilterToggleList" data-testid={testId}>
    <div className="flex items-center justify-between pb-4">
      <h3 className="FilterModal__label" data-testid="filter-toggle-list-label">
        {label}
      </h3>
      <button
        disabled={activeToggleCount === 0}
        className="FilterModal__clear"
        onClick={clearFunc}
        data-testid={`clear-${testId}`}
      >
        Clear
      </button>
    </div>
    <div className="FilterModal__toggle-list">{children}</div>
  </div>
)

export default FilterModal
